import React from "react";
import {HeaderLayout} from "../layouts/HeaderLayout";
import {Box, Button, Stack, useMediaQuery, useTheme} from "@mui/material";
import {Claim} from "../components/Claim";
import {ComeFunziona} from "../components/ComeFunziona";
import {FloatingButton} from "../components/FloatingButton";
import {GestioneDomande} from "../components/GestioneDomande";
import {GestioneDomandeRighe} from "../components/GestioneDomande/GestioneDomandeRighe";
import {OttieniStrumento} from "../components/OttieniStrumento";
import {BandaProva} from "../components/BandaProva";
import {InAzione} from "../components/InAzione";
import {FormDemo} from "../components/FormDemo";

export const Home = () => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up("md"))
    return (
        <Claim/>
    )
}
