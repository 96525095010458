import logo from './logo.svg';
import './App.css';
import {Home} from "./pages/Home";
import {CssBaseline} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {LanguageRoute} from "./components/LanguageRoute";
import RichiediDemo from "./pages/RichiediDemo";

function App() {
  return (
    <div>
        <CssBaseline/>
        <Routes>
            <Route element={<LanguageRoute/>}>
                <Route path="/:lang" element={<Home/>}/>
                <Route path="/:lang/grazie-informazioni-toolpress" element={<RichiediDemo/>}/>
            </Route>
            <Route path="/" element={<Home/>}/>
            <Route path="/grazie-informazioni-toolpress" element={<RichiediDemo/>}/>
        </Routes>
    </div>
  );
}

export default App;
