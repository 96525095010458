import React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {ClaimCards} from "./Claim/ClaimCards";
import {Trans, useTranslation} from "react-i18next";
import {LangSwitcher} from "./LangSwitcher";

export const Claim = () => {
    const {t} = useTranslation()
    return (
        <Box
            sx={{background: {xs: '#121422', md: 'linear-gradient(90deg, rgba(19,22,44,1) 0%, rgba(17,17,17,1) 67%)', minHeight:'100vh'}}}>
            <Box sx={{p: 2, width: '100%', maxWidth: "88rem", margin: 'auto', pb: 10, minHeight:'100%'}}>
                <Stack justifyContent={'right'} sx={{pb:5, width:'100%'}}>
                    <LangSwitcher/>
                </Stack>
                <Stack justifyContent={'center'} sx={{minHeight:'70vh'}}>
                    <Stack spacing={4} alignItems={'center'} justifyContent={'space-between'}>
                        <Box sx={{width:'20rem'}}>
                            <img style={{width:'100%'}} src={require('../assets/images/logo_toolpress.png')}/>
                        </Box>
                        <Box>
                            <Typography variant={'h4'}>
                                {t('claim.partof')}
                            </Typography>
                        </Box>
                        <Box sx={{textAlign:'center'}}>
                            <Box sx={{width:'30rem', margin:'auto', pb:2}}>
                                <a href={'https://2ndstage.app/'}>
                                    <img style={{width:'100%'}} src={require('../assets/images/logo-2ndstage.png')}/>
                                </a>
                            </Box>
                            <Button variant={'contained'} href={'https://2ndstage.app/'}>
                                {t('claim.visit')}
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            </Box>
        </Box>

    )
}
