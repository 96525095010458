import {createTheme} from "@mui/material";

export const toolpressTheme = createTheme({
    typography:{
        fontFamily:"Sharp Grotesk"
    },
    components:{
        MuiCard:{
            variants:[
                {
                    props:{variant:'claim'},
                    style:{
                        background:'#181822',
                        border: '1px solid #3C3C4D',
                        borderRadius:"2rem"
                    }
                },
                {
                    props:{variant:'extra'},
                    style:{
                        background: '#242431',
                        border:'1px solid #6B6B80',
                        borderRadius: 0
                    }
                }
            ]

        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#252a33",
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "#252a33",
                    "& input": {
                        textAlign: "center"
                    }
                },
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    background: 'white',
                    borderRadius: '1rem',
                    borderColor: '#252a33',
                    boxShadow: 'none',
                    '& fieldset': {
                        borderRadius: `1rem`,
                        textAlign: "center"
                    },
                }
            }
        },
        MuiButton:{
            variants:[
                {
                    props:{variant:'richiedi'},
                    style:{
                        border: '1px solid black',
                        background:"transparent",
                        fontSize:'1.1rem',
                        color:'black',
                        '&:hover':{
                            background:'black',
                            color:'#E6FC69'
                        },
                        '@media screen and (max-width: 767px)':{
                            fontSize:'1.2rem'
                        }
                    }
                }
            ]
        }
    },
    palette:{
        mode:'dark',
        background:{
            default:"#121422"
        },
        primary: {
            main:"#E6FC69"
        },
        secondary:{
            main:"#9F84E1"
        }
    }
})
