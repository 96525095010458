import React from "react";
import {InlineWidget} from "react-calendly";
import {Box, Container} from "@mui/material";
import Typography from "@mui/material/Typography";

const RichiediDemo = () => {
    return (
        <Container>
            <Box sx={{width:'min(15rem, 100%)', margin:'auto', pt:4}}>
                <img style={{width:'100%'}} src={require('../assets/images/toolpress_by_plesh.png')}/>
            </Box>
            <Box sx={{pt:4}}>
                <Typography textAlign={'center'}>
                    Grazie di averci contattato, ti abbiamo inviato una email di conferma da parte di Marco, nostro responsabile operativo.
                    <br/>
                    Se vuoi puoi prenotare subito una call informativa con il nostro team qui sotto.
                </Typography>
            </Box>
            <Box style={{height:'75vh'}}>
                <InlineWidget styles={{height:'100%'}} url="https://calendly.com/plesh/call-informativa-toolpress"/>
            </Box>
        </Container>
    );
};

export default RichiediDemo;
